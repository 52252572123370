const deleteSubUser = async (userId, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/subUsers/${userId}`;

    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default deleteSubUser;
