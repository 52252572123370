import React, { useState, useEffect, useRef } from 'react';
import './ProfileHeader.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/userSlice';
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../i18n";
import { Link, useLocation } from 'react-router-dom';
import { fetchUserData } from '../../store/userSlice';
import LanguageSwitcher from '../LanguageSwitcher';

const ProfileHeader = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const pageTitle = document.title;

    const user = useSelector((state) => state.user);
    const { availableOrderTypes } = useSelector((state) => state.availableOrderTypes);
    const dispatch = useDispatch();

    const profileHeaderRef = useRef(null);

    const [hasResidentialProxyOrderPool1, setHasResidentialProxyOrderPool1] = useState(false)
    const [hasResidentialProxyOrderPool2, setHasResidentialProxyOrderPool2] = useState(false)

    const [openedIndex, setOpenedIndex] = useState(null);
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

    const toggleMobileMenu = () => {
        const body = document.body;
        setIsMobileMenuOpened((prevState) => {
            const newState = !prevState;
            if (newState) {
                body.classList.add('no-scroll');
            } else {
                body.classList.remove('no-scroll');
            }
            return newState;
        });
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    useEffect(() => {
        if (user.token) {
            dispatch(fetchUserData(user.token));
        }
    }, [location.pathname, dispatch]);

    const handleToggle = (index) => {
        setOpenedIndex(openedIndex === index ? null : index);
    };

    const handleClickOutside = (event) => {
        if (profileHeaderRef.current && !profileHeaderRef.current.contains(event.target)) {
            setOpenedIndex(null);
        }
    };

    useEffect(() => {
        if (availableOrderTypes && availableOrderTypes.length > 0) {
            setHasResidentialProxyOrderPool1(availableOrderTypes.some(order => order.type === 'residential-proxy-pool-1'))
            setHasResidentialProxyOrderPool2(availableOrderTypes.some(order => order.type === 'residential-proxy-pool-2'))
        }
    }, [availableOrderTypes]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="profile-header" ref={profileHeaderRef}>
            <div className="container">
                <h1 className="text-h1">{pageTitle}</h1>
                <ul className='profile-header-nav'>
                    <li className={`profile-header-nav__item`}>
                        <LanguageSwitcher />
                    </li>
                    <li className={`profile-header-nav__item`}>
                        <div className='profile-header-nav__header'>
                            <div className='profile-header-nav__icon'>
                                <img src='/wallet.svg' alt='wallet' />
                            </div>
                            <span className='profile-header-nav__title'>{t('name-balance')}: {Math.ceil((user?.data?.balance || 0) * 100) / 100}$</span>
                        </div>
                    </li>
                    <li className={`profile-header-nav__item has-sub ${openedIndex === 1 ? 'opened' : ''}`}>
                        <div className='profile-header-nav__header' onClick={() => handleToggle(1)}>
                            <div className='profile-header-nav__icon'>
                                <img src='/user.svg' alt='user' />
                            </div>
                            <span className='profile-header-nav__title'>{t('name-email')}: {user?.data?.email}</span>
                            <img className='profile-header-nav__arrow' src='/dropdown-arrow.svg' alt='dropdown' />
                        </div>
                        {openedIndex === 1 && (
                            <div className='sub-menu'>
                                <div className='sub-list'>
                                    <Link className='sub-list__item' to={'/account/change-data'}>
                                        {t('button-change-data')}
                                    </Link>
                                    <div className='sub-list__item' onClick={handleLogout}>
                                        {t('button-logout')}
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
                <div className='burger-btn' onClick={toggleMobileMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={`m-menu ${isMobileMenuOpened ? "opened" : ''}`}>
                <div className='container'>
                    <div className='m-menu__header' onClick={toggleMobileMenu}>
                        <img className='m-menu__arrow' src='/icons8-arrow-50.png' alt='arrow'></img>
                        <h3 className='m-menu__title'>PSB-Proxy</h3>
                    </div>
                    <div className='m-menu__content'>
                        <nav className='m-nav'>
                            <Link className='m-nav__item' to={"/account"} onClick={toggleMobileMenu}>
                                <span>{t('name-home-page')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/account/shop/residential-proxy"} onClick={toggleMobileMenu}>
                                <span>{t("name-buy")} {t('name-residential-proxy')}</span>
                            </Link>
                            {
                                hasResidentialProxyOrderPool1 &&
                                <Link className='m-nav__item' to={"/account/orders/residential-proxy/pool-1"} onClick={toggleMobileMenu}>
                                    <span>{t("name-use")} Pool 1</span>
                                </Link>
                            }
                            {
                                hasResidentialProxyOrderPool2 &&
                                <Link className='m-nav__item' to={"/account/orders/residential-proxy/pool-2"} onClick={toggleMobileMenu}>
                                    <span>{t("name-use")} Pool 2</span>
                                </Link>
                            }
                            <Link className='m-nav__item' to={"/account/wallet"} onClick={toggleMobileMenu}>
                                <span>{t('name-wallet')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/account/partnership"} onClick={toggleMobileMenu}>
                                <span>{t('name-api')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/account/change-data"} onClick={toggleMobileMenu}>
                                <span>{t('name-change-data')}</span>
                            </Link>
                            <LanguageSwitcher />
                        </nav>
                    </div>
                    <div className='m-menu__footer'>
                        <div className='primary-btn' onClick={handleLogout}>
                            {t('button-logout')}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default ProfileHeader;
