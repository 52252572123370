function processErrors(errorResponse) {
    if (!errorResponse || typeof errorResponse !== 'object') {
        return 'errors.unexpected';
    }

    if (errorResponse.message) {
        switch (errorResponse.message) {
            case ("\"username\" is required"):
                return 'error-empty-username';
            case ("\"email\" is required"):
                return 'error-empty-email';
            case ("Must be a valid email address"):
                return 'error-invalid-email';
            case ("\"password\" is required"):
                return 'error-empty-password';
            case ("Password should have at least 6 characters"):
                return 'error-short-password';
            case ("\"role\" is required"):
                return 'error-empty-role';
            case ("Not enough traffic available in basic subuser"):
                return 'error-not-enough-traffic';
            case ("\"balance\" is required"):
                return 'error-empty-balance';
            case ("Traffic available must be an integer"):
                return 'error-traffic-not-integer';
            case ("\"newsletter\" is required"):
                return 'error-empty-newsletter';
            case ("\"type\" must be one of residential-proxy-pool-1, residential-proxy-pool-2"):
                return 'error-invalid-sub-user-type';
            case ("\"traffic_available\" is required"):
                return 'error-empty-traffic-available';
            case ("Email is already in use."):
                return 'error-email-taken';
            case ("Email already used!"):
                return 'error-email-taken';
            case ("Invalid password or user does not exist"):
                return 'error-auth-error';
            case ("Too many login attempts from this IP, please try again after 15 minutes"):
                return 'error-too-many-attempts';
            case ("Email is already in use"):
                return 'error-email-taken';
            case ("Insufficient balance!"):
                return 'error-balance-insufficient';
            case ("Duplicate entry"):
                return 'error-duplicate-entry';
            case ("Token expired. Please log in again."):
                return 'error-auth-expired';
            case ("The ip must be a valid IPv4 address."):
                return 'error-invalid-ip-address';
            case ("The proxy count must be between 1 and 1000."):
                return 'error-invalid-proxy-count';
            case ("The ip must be a valid IP address."):
                return 'error-invalid-ip';
            case ("The amount of traffic difference must be greater than 0."):
                return 'error-invalid-traffic-difference';
            case ("Result balance one of the subUsers cannot be equal zero or less than zero."):
                return 'error-subUser-balance-zero';
            case ("Token expired."):
                return 'error-token-expired';
            case ("Forbidden"):
                return 'error-token-expired';
            default:
                return 'error-invalid-input';
        }
    }

    if (errorResponse.status) {
        switch (errorResponse.status) {
            case 400:
                return 'error-bad-request';
            case 401:
                return 'error-unauthorized';
            case 403:
                return 'error-forbidden';
            case 404:
                return 'error-not-found';
            case 500:
                return 'error-server-error';
            default:
                return 'error-unknown';
        }
    }

    return 'error-unexpected';
}

export default processErrors;