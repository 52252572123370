const createSubUser = async (queries, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/subUsers`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(queries)
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default createSubUser;
