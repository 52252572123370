import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
} from "recharts";

// Components
import Popup from "../../components/Popup";
import DatePicker from '../../components/DatePicker';
import SelectComponent from '../../components/SelectComponent';
import PaginationComponent from "../../components/PaginationComponent";
import InputWithCopyComponent from "../../components/InputWithCopyComponent";
import NumericInput from "../../components/NumericInput";
import Breadcrumbs from "../../components/BreadcrumbsComponent";
import HintComponent from "../../components/HintComponent";
import Preloader from "../../components/Preloader";

// API
import getSubUsers from "../../api/subUsers/getSubUsers.js";
import getBasicSubUser from "../../api/subUsers/getBasicSubUser.js";
import getSubUserById from "../../api/subUsers/getSubUserById.js";
import createSubUser from "../../api/subUsers/createSubUser.js";
import deleteSubUser from "../../api/subUsers/deleteSubUser.js";
import updateSubUser from "../../api/subUsers/updateSubUser.js";
import getTrafficStatsById from "../../api/subUsers/getTrafficStatsById.js";

import formatDate from "../../utils/formatDate.js";
import processErrors from '../../utils/processErrors';

export const PartnershipPage = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const [currentTab, setCurrentTab] = useState("name-pool-1-sub-users");

    const [isLoading, setIsLoading] = useState(false);
    const [isOpenSubUserPool1CreatePopup, setIsOpenSubUserPool1CreatePopup] = useState(false);
    const [isOpenSubUserPool2CreatePopup, setIsOpenSubUserPool2CreatePopup] = useState(false);
    const [isOpenSubUserEditPopup, setIsOpenSubUserEditPopup] = useState(false);
    const [isOpenSubUserDeletePopup, setIsOpenSubUserDeletePopup] = useState(false);

    const [isActiveButtonSubmitCreateSubUser, setIsActiveButtonSubmitCreateSubUser] = useState(true);
    const [isActiveButtonSubmitEditSubUser, setIsActiveButtonSubmitEditSubUser] = useState(true);
    const [isActiveButtonSubmitDeleteSubUser, setIsActiveButtonSubmitDeleteSubUser] = useState(true);

    const [subUsers, setSubUsers] = useState([]);
    const [meta, setMeta] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0
    });

    const [residentialProxyData, setResidentialProxyData] = useState({});
    const [basicSubUserDataPool1, setBasicSubUserDataPool1] = useState(null);
    const [basicSubUserDataPool2, setBasicSubUserDataPool2] = useState(null);

    const [trafficStats, setTrafficStats] = useState([]);
    const [selectedTrafficStatsTab, setSelectedTrafficStatsTab] = useState("resource-using");
    const [selectedTrafficUnit, setSelectedTrafficUnit] = useState({
        "name": "GB",
        "value": "gb",
    });
    const [selectedDate, setSelectedDate] = useState("2024-11-08T19:21:06.585Z");

    const available_traffic_units = [
        {
            "name": "MB",
            "value": "mb",
        },
        {
            "name": "GB",
            "value": "gb",
        }
    ]
    const available_sub_user_types = [
        {
            "name": t('name-residential-proxy-pool-1'),
            "value": "residential-proxy-pool-1",
        },
        {
            "name": t('name-residential-proxy-pool-2'),
            "value": "residential-proxy-pool-2",
        }
    ]

    const [createSubUserType, setCreateSubUserType] = useState(null);
    const [createSubUserUsername, setCreateSubUserUsername] = useState("");
    const [createSubUserPassword, setCreateSubUserPassword] = useState("");
    const [createSubUserTrafficAvailable, setCreateSubUserTrafficAvailable] = useState("");

    const [editSubUserData, setEditSubUserData] = useState({});
    const [deleteSubUserData, setDeleteSubUserData] = useState({});

    const [editSubUserUsername, setEditSubUserUsername] = useState("");
    const [editSubUserPassword, setEditSubUserPassword] = useState("");
    const [editSubUserTrafficAvailable, setEditSubUserTrafficAvailable] = useState("");
    const [editSubUserTrafficUsed, setEditSubUserTrafficUsed] = useState("");

    const [deleteSubUserUsername, setDeleteSubUserUsername] = useState("");

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const fillMissingDates = (data) => {
        if (!data?.length) return [];

        const sortedData = [...data].sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        const filledData = [];

        for (let i = 0; i < sortedData.length; i++) {
            const currentItem = sortedData[i];
            const nextItem = sortedData[i + 1];

            filledData.push({
                traffic_balance: currentItem.traffic_balance,
                createdAt: currentItem.createdAt,
            });

            if (nextItem) {
                const currentDate = new Date(currentItem.createdAt);
                const nextDate = new Date(nextItem.createdAt);

                const hoursDiff = Math.floor(
                    (nextDate - currentDate) / (1000 * 60 * 60)
                );

                for (let j = 1; j < hoursDiff; j++) {
                    const filledDate = new Date(
                        currentDate.getTime() + j * 60 * 60 * 1000
                    );

                    filledData.push({
                        traffic_balance: currentItem.traffic_balance,
                        createdAt: filledDate.toISOString(),
                    });
                }
            }
        }

        return filledData.map((item) => ({
            traffic_balance: item.traffic_balance,
            createdAt: formatDate(item.createdAt, "yyyy-MM-dd h:m:s"),
        }));
    };

    const fetchSubUsers = async (page = meta.currentPage) => {
        try {
            let params = {
                page: page,
                searchBy: "type",
            };

            switch (currentTab) {
                case 'name-pool-1-sub-users':
                    params.searchValue = "residential-proxy-pool-1";
                    break;
                case 'name-pool-2-sub-users':
                    params.searchValue = "residential-proxy-pool-2";
                    break;
                default:
                    break;
            }

            const data = await getSubUsers(params, user.token);
            if (data?.data) {
                setSubUsers(data.data);
            }

            if (data?.meta) {
                setMeta({
                    currentPage: data.meta.currentPage,
                    pageSize: data.meta.pageSize,
                    totalPages: data.meta.totalPages,
                    totalItems: data.meta.totalItems
                });
            }
        } catch (error) {
            console.error('Error fetching sub-users:', error);
            toast.error(t('error-fetching-sub-users'));
        }
    };

    const fetchTrafficStats = async (id) => {
        try {
            const response = await getTrafficStatsById(id, user.token);
            const data = await response.json();
            setResidentialProxyData({
                id: id,
                traffic_stats: data
            });
            if (data && data?.length > 0) {
                const formatted_traffic_stats = data?.map((item) => ({
                    traffic_balance: item?.traffic_balance,
                    createdAt: formatDate(item?.createdAt, "yyyy-MM-dd h:m:s")
                })) || [];
                setTrafficStats(formatted_traffic_stats)

                const formatDateForInput = (dateString) => {
                    const date = new Date(dateString);
                    return date.toISOString().split('T')[0];
                };

                setSelectedDate({
                    start_date: formatDateForInput(data[0]?.createdAt),
                    end_date: formatDateForInput(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString())
                });

                toast.success(t('name-traffic-stats-found'));
            } else {
                toast.info(t('name-no-traffic-stats-found'));
            }
        } catch (error) {
            console.error('Error fetching traffic stats:', error);
            toast.error(t('error-fetching-traffic-stats'));
        }
    };

    const fetchBasicSubUser = async () => {
        try {
            const basicSubUserPool1Data = await getBasicSubUser({ type: "residential-proxy-pool-1" }, user.token);
            const basicSubUserPool2Data = await getBasicSubUser({ type: "residential-proxy-pool-2" }, user.token);

            if (basicSubUserPool1Data) {
                setBasicSubUserDataPool1(basicSubUserPool1Data);
            } else {
                setBasicSubUserDataPool1(null);
            }

            if (basicSubUserPool2Data) {
                setBasicSubUserDataPool2(basicSubUserPool2Data);
            } else {
                setBasicSubUserDataPool2(null);
            }
        }
        catch (error) {
            console.error('Error fetching basic sub-user:', error);
            toast.error(t('error-fetching-basic-sub-user'));
        }
    }

    const handleViewTrafficStats = (id) => {
        fetchTrafficStats(id);
    };

    const handlePageChange = (newPage) => {
        setMeta(prevMeta => ({
            ...prevMeta,
            currentPage: newPage
        }));

        fetchSubUsers(newPage);
    };

    const handleCreateSubUserOpen = (type) => {
        if (type === "residential-proxy-pool-1") {
            setIsOpenSubUserPool1CreatePopup(true);
            setCreateSubUserType(null);
        } else if (type === "residential-proxy-pool-2") {
            setIsOpenSubUserPool2CreatePopup(true);
            setCreateSubUserType(null);
        }
    }

    const handleUpdateSubUserOpen = async (subUser) => {
        const response = await getSubUserById(subUser.id, user.token);
        const responseJson = await response.json();

        if (!response.ok) {
            const errorMessage = processErrors(responseJson);
            
            throw new Error(errorMessage);
        }

        setSubUsers(subUsers.map((item) => {
            if (item.id === subUser.id) {
                return responseJson;
            }
            return item;
        }));

        setIsOpenSubUserEditPopup(true);
        setEditSubUserData(responseJson);
        setEditSubUserUsername(responseJson?.data?.username);
        setEditSubUserPassword(responseJson?.data?.password);
        setEditSubUserTrafficAvailable(responseJson?.data?.traffic_available);
        setEditSubUserTrafficUsed(responseJson?.data?.traffic_used);
    };

    const handleDeleteSubUserOpen = (subUser) => {
        setIsOpenSubUserDeletePopup(true);
        setDeleteSubUserData(subUser);
        setDeleteSubUserUsername("");
    };

    const handleCreateSubUserSubmit = async () => {
        try {
            let queries = {};

            switch (currentTab) {
                case 'name-pool-1-sub-users':
                    queries.type = "residential-proxy-pool-1";
                    break;
                case 'name-pool-2-sub-users':
                    queries.type = "residential-proxy-pool-2";
                    break;
                default:
                    toast.error(t('error-invalid-sub-user-type'));
                    break;
            }

            if (queries.type === "residential-proxy-pool-2" && createSubUserTrafficAvailable) {
                queries.traffic_available = createSubUserTrafficAvailable;
            }

            setIsActiveButtonSubmitCreateSubUser(false);

            const response = await createSubUser(queries, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-sub-user-created'));
            setIsOpenSubUserPool1CreatePopup(false);
            setIsOpenSubUserPool2CreatePopup(false);
            setTimeout(() => {
                fetchSubUsers();
                fetchBasicSubUser();
            }, 1000);            
            setIsActiveButtonSubmitCreateSubUser(true);
        } catch (error) {
            toast.error(t(error.message));
            setIsActiveButtonSubmitCreateSubUser(true);
        }
    };

    const handleUpdateSubUserSubmit = async () => {
        try {
            let updateData = {};

            if (editSubUserUsername !== editSubUserData?.data?.username) {
                updateData.username = editSubUserUsername;
            }

            if (editSubUserPassword !== editSubUserData?.data?.password) {
                updateData.password = editSubUserPassword;
            }

            if (editSubUserTrafficAvailable !== editSubUserData?.data?.traffic_available) {
                updateData.traffic_available = editSubUserTrafficAvailable;
            }

            if (Object.keys(updateData).length === 0) {
                toast.info(t('notification-no-changes'));
                setIsOpenSubUserEditPopup(false);
                return;
            }

            setIsActiveButtonSubmitEditSubUser(false);

            const response = await updateSubUser(editSubUserData.id, updateData, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-sub-user-updated'));
            setIsOpenSubUserEditPopup(false);
            setTimeout(() => {
                fetchSubUsers();
                fetchBasicSubUser();
            }, 1000);            
            setIsActiveButtonSubmitEditSubUser(true);
        } catch (error) {
            toast.error(t(error.message));
            setIsActiveButtonSubmitEditSubUser(true);
        }
    };

    const handleDeleteSubUserSubmit = async () => {
        try {
            setIsActiveButtonSubmitDeleteSubUser(false);
            const response = await deleteSubUser(deleteSubUserData.id, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-sub-user-deleted'));
            setIsOpenSubUserDeletePopup(false);
            setTimeout(() => {
                fetchSubUsers();
                fetchBasicSubUser();
            }, 1000);            
            setIsActiveButtonSubmitDeleteSubUser(true);
        } catch (error) {
            toast.error(t(error.message));
            setIsActiveButtonSubmitDeleteSubUser(true);
        }
    };

    const convertToMB = (valueInGB) => valueInGB * 1024;

    const filterByDateRange = (data, startDate, endDate) => {
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);

        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);

        return data.filter(item => {
            const itemDate = new Date(item.createdAt);
            return itemDate >= start && itemDate <= end;
        });
    };

    const groupBy = (array, keyExtractor) => {
        return array.reduce((result, item) => {
            const key = keyExtractor(item);
            if (!result[key]) result[key] = [];
            result[key].push(item);
            return result;
        }, {});
    };

    useEffect(() => {
        if (residentialProxyData?.traffic_stats?.length > 0) {
            const trafficStatsDateFiltered = filterByDateRange(
                fillMissingDates(residentialProxyData?.traffic_stats),
                selectedDate?.start_date,
                selectedDate?.end_date
            );

            const rangeDays = Math.ceil(
                (new Date(selectedDate?.end_date) - new Date(selectedDate?.start_date)) / (1000 * 60 * 60 * 24)
            ) + 2;

            const convertTrafficValue = (value) => selectedTrafficUnit.value === "mb" ? convertToMB(value) : value;

            const calculateTrafficUsed = (items) =>
                items.map((item, index, array) => {
                    if (index === 0) {
                        return {
                            ...item,
                            traffic_balance: 0,
                            createdAt: item?.createdAt,
                        };
                    } else {
                        const previousItem = array[index - 1];
                        const currentBalance = item.traffic_balance;
                        const previousBalance = previousItem.traffic_balance;

                        const trafficUsed = previousBalance > currentBalance
                            ? (previousBalance - currentBalance).toFixed(2)
                            : 0;

                        return {
                            ...item,
                            traffic_balance: parseFloat(trafficUsed),
                            createdAt: item?.createdAt,
                        };
                    }
                });

            const groupAndProcessStats = (data, format) => {
                const grouped = groupBy(data, (item) => formatDate(item.createdAt, format));
                return Object.entries(grouped).map(([key, items]) => ({
                    createdAt: formatDate(key, format),
                    traffic_balance: convertTrafficValue(Math.min(...items.map((item) => item.traffic_balance))),
                }));
            };

            let processedStats;
            if (rangeDays >= 7 && rangeDays <= 180) {
                processedStats = groupAndProcessStats(trafficStatsDateFiltered, "yyyy-month-dd");
            } else if (rangeDays > 180) {
                processedStats = groupAndProcessStats(trafficStatsDateFiltered, "yyyy-MM");
            } else {
                processedStats = groupAndProcessStats(trafficStatsDateFiltered);
            }

            if (selectedTrafficStatsTab === "resource-left") {
                setTrafficStats(processedStats);
            } else if (selectedTrafficStatsTab === "resource-using") {
                setTrafficStats(calculateTrafficUsed(processedStats));
            }
        }
    }, [residentialProxyData, selectedTrafficStatsTab, selectedTrafficUnit, selectedDate]);

    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <div className="graph-tooltip">
                        <div className="graph-tooltip__value">{payload[0].value} <span className="green">{selectedTrafficUnit?.name}</span></div>
                        <div className="graph-tooltip__name">{label}</div>
                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([fetchBasicSubUser(), fetchSubUsers()]);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([fetchBasicSubUser(), fetchSubUsers()]);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [currentTab]);

    return (
        <>
            <Helmet>
                <title>{t("page-title-partnership")}</title>
            </Helmet>
            {
                isLoading && <Preloader />
            }
            <div>
                {/* <Breadcrumbs
                    current={t("partnership")}
                    links={[{ title: t("home"), url: "/" }]}
                /> */}
                <div className="partnership-page">
                    <div className="container">
                        <div className="tabs-wrapper" style={{ gridColumn: "span 12" }}>
                            <button
                                className={"tab-item " + (currentTab === 'name-pool-1-sub-users' ? 'selected' : "")}
                                disabled={!basicSubUserDataPool1}
                                onClick={() => setCurrentTab('name-pool-1-sub-users')}
                            >
                                {t('name-pool-1-sub-users')}
                                {
                                    !basicSubUserDataPool1 && <div className="tooltip" data-tooltip={t("hint-no-traffic-for-sub-users-create")} ></div>
                                }
                            </button>
                            <button
                                className={"tab-item " + (currentTab === 'name-pool-2-sub-users' ? 'selected' : "")}
                                disabled={!basicSubUserDataPool2}
                                onClick={() => setCurrentTab('name-pool-2-sub-users')}
                            >
                                {t('name-pool-2-sub-users')}
                                {
                                    !basicSubUserDataPool2 && <div className="tooltip" data-tooltip={t("hint-no-traffic-for-sub-users-create")} ></div>
                                }
                            </button>
                        </div>
                        <div className="page-section" style={{ gridColumn: "span 6" }}>
                            <div className="page-section__header">
                                <h2 className="page-section__h2">{t("name-your-api-key")}</h2>
                            </div>
                            <div className="page-section__content">
                                <InputWithCopyComponent
                                    value={user.token}
                                    type="password"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="page-section" style={{ gridColumn: "span 6" }}>
                            <div className="page-section__header">
                                <h2 className="page-section__h2">{t("name-information")}</h2>
                            </div>
                            <div className="page-section__content">
                                <h3 className="text-h3">{t("name-documentation")}: <a className="link" href="https://documenter.getpostman.com/view/32579516/2sAYX2LiVN" target="_blank" rel="noopener noreferrer">{t("name-link")}</a></h3>
                                <br />
                                {/* {
                                    currentTab === 'name-pool-1-sub-users' &&
                                    <>
                                        <h3 className="text-h3">{t("name-terms-pool-1")}: <Link className="link" to={"/support/pool-1-terms"}>{t("name-link")}</Link></h3>
                                        <br />
                                    </>
                                } */}
                                <h3 className="text-h3">{t("name-support")}: <a className="link" href="https://t.me/psbhosting" target="_blank" rel="noopener noreferrer">{t("name-link")}</a></h3>
                            </div>
                        </div>
                        <div className="form" style={{ gridColumn: "span 12" }}>
                            <div className="form-header">
                                <h2 className="text-h2">{t('name-available-traffic')}</h2>
                            </div>
                            <div className="input-wrapper_fade">
                                {
                                    currentTab === "name-pool-1-sub-users" &&
                                    <input className="input traffic-left" style={{ textAlign: "center", opacity: 1, cursor: "auto" }} value={basicSubUserDataPool1?.data?.traffic_available ? `${Number(basicSubUserDataPool1.data.traffic_available).toFixed(5)} ${t("name-gb")}` : `0 ${t("name-gb")}`} disabled={true} type="text" />
                                }
                                {
                                    currentTab === "name-pool-2-sub-users" &&
                                    <input className="input traffic-left" style={{ textAlign: "center", opacity: 1, cursor: "auto" }} value={basicSubUserDataPool2?.data?.traffic_available ? `${Number(basicSubUserDataPool2.data.traffic_available).toFixed(5)} ${t("name-gb")}` : `0 ${t("name-gb")}`} disabled={true} type="text" />
                                }
                            </div>
                        </div>
                        <div className="page-section" style={{ gridColumn: "span 12" }}>
                            <div className="page-section__header">
                                <div className="flex-wrapper">
                                    <h2 className="page-section__h2">{t("name-sub-users")}</h2>
                                    {
                                        basicSubUserDataPool1?.data?.traffic_available > 0 && currentTab === "name-pool-1-sub-users" ? <button className="primary-btn" disabled={!basicSubUserDataPool1?.data?.traffic_available} onClick={(e) => (handleCreateSubUserOpen("residential-proxy-pool-1"))} style={{ marginLeft: "auto" }}>{t("button-add-sub-user")}</button> :
                                            basicSubUserDataPool2?.data?.traffic_available > 0 && currentTab === "name-pool-2-sub-users" ? <button className="primary-btn" disabled={!basicSubUserDataPool2?.data?.traffic_available} onClick={(e) => (handleCreateSubUserOpen("residential-proxy-pool-2"))} style={{ marginLeft: "auto" }}>{t("button-add-sub-user")}</button> :
                                                <button className="primary-btn" disabled={true} data-tooltip={t("hint-no-traffic-for-sub-users-create")} style={{ marginLeft: "auto" }}>{t("button-add-sub-user")}</button>
                                    }
                                </div>
                            </div>
                            <div className="page-section__content">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("name-username")}</th>
                                            <th>{t("name-traffic-available")}</th>
                                            <th>{t("name-traffic-used")}</th>
                                            <th>{t("name-date-create")}</th>
                                            <th>{t("name-date-updated")}</th>
                                            <th>{t("name-actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            subUsers && subUsers?.length > 0 ? subUsers.map((subUser, index) => (
                                                <tr key={index}>
                                                    <td>{subUser.id}</td>
                                                    <td>{subUser?.data.username}</td>
                                                    <td>{subUser?.data.traffic_available}</td>
                                                    <td>{subUser?.data.traffic_used}</td>
                                                    <td>{formatDate(subUser.createdAt)}</td>
                                                    <td>{formatDate(subUser.updatedAt)}</td>
                                                    <td>
                                                        <div className="buttons-wrapper" style={{ gap: "8px" }}>
                                                            <button
                                                                className="edit-button"
                                                                data-tooltip={t('name-view-traffic-stats')}
                                                                onClick={() => handleViewTrafficStats(subUser.id)}
                                                            >
                                                                <img src="/view-alt-1-svgrepo-com.svg" alt="" />
                                                            </button>
                                                            <button
                                                                className="edit-button"
                                                                data-tooltip={t('name-edit-sub-user')}
                                                                onClick={() => handleUpdateSubUserOpen(subUser)}
                                                            >
                                                                <img src="/edit-3-svgrepo-com.svg" alt="" />
                                                            </button>
                                                            <button
                                                                className="delete-button"
                                                                data-tooltip={t('name-delete-sub-user')}
                                                                onClick={() => handleDeleteSubUserOpen(subUser)}
                                                            >
                                                                <img src="/delete-2-svgrepo-com.svg" alt="" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="1">#</td>
                                                    <td colSpan="6">{t("name-no-data")}</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                                {
                                    subUsers && subUsers?.length > 0 &&
                                    <PaginationComponent
                                        meta={meta}
                                        onPageChange={handlePageChange}
                                    />
                                }
                            </div>
                        </div>
                        <div className="page-section proxylist-wrapper" style={{ gridColumn: "span 12" }}>
                            <div className="page-section__header form-header">
                                <div>
                                    <h2 className="text-h2">{t("name-statistics")} {residentialProxyData?.id && `${t("name-sub-user")} - #${residentialProxyData.id}`}</h2>
                                    <div className="graph-tabs">
                                        <div
                                            className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-using" ? "selected" : ""}`}
                                            onClick={() => setSelectedTrafficStatsTab("resource-using")}
                                        >
                                            {t('name-resource-using')}
                                        </div>
                                        {/* <div
                                        className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-left" ? "selected" : ""}`}
                                        onClick={() => setSelectedTrafficStatsTab("resource-left")}
                                    >
                                        {t('name-value-resources-left')}
                                    </div> */}
                                    </div>
                                </div>
                                <div>
                                    <SelectComponent items={available_traffic_units} selectedItem={selectedTrafficUnit} onItemSelected={setSelectedTrafficUnit}></SelectComponent>
                                    <DatePicker
                                        mode="range"
                                        allowFutureDates={false}
                                        initialSelectedDate={selectedDate}
                                        onDateChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            <div className="page-section__content">
                                {
                                    trafficStats && trafficStats?.length > 0 ?
                                        <ResponsiveContainer width="100%" height={250}>
                                            <AreaChart
                                                data={trafficStats}
                                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                            >
                                                <defs>
                                                    <linearGradient id="colorDifficulty" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#A038AA" stopOpacity={0.15} />
                                                        <stop offset="95%" stopColor="#A038AA" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                                <CartesianGrid />
                                                <XAxis dataKey="createdAt" label={{ value: '', position: 'insideBottomRight', offset: 0 }} />
                                                <YAxis
                                                    dataKey="traffic_balance"
                                                    label={{ value: selectedTrafficUnit?.name, angle: -90, position: 'insideLeft' }}
                                                    domain={[
                                                        0,
                                                        Math.ceil(
                                                            (Math.max(...trafficStats.map(item => item.traffic_balance)) * 1.1) * 100
                                                        ) / 100
                                                    ]}
                                                />
                                                <Tooltip content={<CustomTooltip2 />} />
                                                <Area
                                                    type="monotone"
                                                    dataKey="traffic_balance"
                                                    stroke="#4E9AA5"
                                                    fillOpacity={1}
                                                    strokeWidth={2}
                                                    fill="transparent"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer> : t("name-no-data")
                                }
                            </div>
                        </div>
                        <Popup title={t("popup-title-sub-user-create-pool-1")} isSmall={true} trigger={isOpenSubUserPool1CreatePopup} setTrigger={setIsOpenSubUserPool1CreatePopup}>
                            <div className="popup__content">
                                <p className="text-p" style={{ marginBottom: "35px" }}>{t("name-sub-user-create-hint")}</p>
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-type")}</h2>
                                    </div>
                                    <div className="form-section__content">
                                        <SelectComponent
                                            items={available_sub_user_types}
                                            selectedItem={available_sub_user_types[0]}
                                            disabled={true}
                                            onItemSelected={setCreateSubUserType}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popup__footer">
                                <div className="buttons-wrapper">
                                    <button className="cancel-btn" onClick={(e) => (setIsOpenSubUserPool1CreatePopup(false))}>{t("button-cancel")}</button>
                                    <button
                                        className="primary-btn"
                                        onClick={(e) => (handleCreateSubUserSubmit())}
                                        disabled={
                                            (basicSubUserDataPool1?.data?.traffic_available <= 0 && currentTab === "name-pool-1-sub-users") ||
                                            (basicSubUserDataPool2?.data?.traffic_available <= 0 && currentTab === "name-pool-2-sub-users") ||
                                            !isActiveButtonSubmitCreateSubUser
                                        }
                                    >{t("button-create")}</button>
                                </div>
                            </div>
                        </Popup>
                        <Popup title={t("popup-title-sub-user-create-pool-2")} isSmall={true} trigger={isOpenSubUserPool2CreatePopup} setTrigger={setIsOpenSubUserPool2CreatePopup}>
                            <div className="popup__content">
                                <p className="text-p" style={{ marginBottom: "35px" }}>{t("name-sub-user-create-hint-pool-2")}</p>
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-type")}</h2>
                                    </div>
                                    <div className="form-section__content">
                                        <SelectComponent
                                            items={available_sub_user_types}
                                            selectedItem={available_sub_user_types[1]}
                                            disabled={true}
                                            onItemSelected={setCreateSubUserType}
                                        />
                                        <div className="form-section">
                                            <div className="form-section__header">
                                                <h2 className="form-section__h2">{t("name-traffic-available")}</h2>
                                            </div>
                                            <div className="form-section__content">
                                                <NumericInput
                                                    min={0.01}
                                                    max={
                                                        currentTab === "name-pool-1-sub-users" ? basicSubUserDataPool2?.data?.traffic_available :
                                                            currentTab === "name-pool-2-sub-users" ? basicSubUserDataPool2?.data?.traffic_available : 0
                                                    }
                                                    onChange={setCreateSubUserTrafficAvailable}
                                                    placeholder={t("name-traffic-available")}
                                                    value={createSubUserTrafficAvailable}
                                                />
                                                {
                                                    createSubUserTrafficAvailable > Number(basicSubUserDataPool1?.data?.traffic_available).toFixed(5) && currentTab === "name-pool-1-sub-users" ?
                                                        <div className="input-error">
                                                            <span className="input-error__icon">
                                                                <img src="/error-16-svgrepo-com.svg" alt="" />
                                                            </span>
                                                            <span className="input-error__text">
                                                                {t("error-traffic-available-exceeds-limit")}
                                                            </span>
                                                        </div> :
                                                        createSubUserTrafficAvailable >= Number(basicSubUserDataPool2?.data?.traffic_available).toFixed(5) && currentTab === "name-pool-2-sub-users" ?
                                                            <div className="input-error">
                                                                <span className="input-error__icon">
                                                                    <img src="/error-16-svgrepo-com.svg" alt="" />
                                                                </span>
                                                                <span className="input-error__text">
                                                                    {t("error-traffic-available-exceeds-limit")}
                                                                </span>
                                                            </div> : ""
                                                }
                                                {
                                                    createSubUserTrafficAvailable < 0.01 &&
                                                    <div className="input-error">
                                                        <span className="input-error__icon">
                                                            <img src="/error-16-svgrepo-com.svg" alt="" />
                                                        </span>
                                                        <span className="input-error__text">
                                                            {t("error-traffic-available-minimum-limit")}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup__footer">
                                <div className="buttons-wrapper">
                                    <button className="cancel-btn" onClick={(e) => (setIsOpenSubUserPool2CreatePopup(false))}>{t("button-cancel")}</button>
                                    <button
                                        className="primary-btn"
                                        onClick={(e) => (handleCreateSubUserSubmit())}
                                        disabled={
                                            (basicSubUserDataPool1?.data?.traffic_available <= 0 && currentTab === "name-pool-1-sub-users") ||
                                            (basicSubUserDataPool2?.data?.traffic_available <= 0 && currentTab === "name-pool-2-sub-users") ||
                                            !isActiveButtonSubmitCreateSubUser
                                        }
                                    >{t("button-create")}</button>
                                </div>
                            </div>
                        </Popup>
                        <Popup title={t("popup-title-sub-user-edit")} isSmall={true} trigger={isOpenSubUserEditPopup} setTrigger={setIsOpenSubUserEditPopup}>
                            <div className="popup__content">
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-username")}</h2>
                                    </div>
                                    <div className="form-section__content">
                                        <div className="input-wrapper_fade">
                                            <input className="input" value={editSubUserUsername} disabled={true} onChange={(e) => (setEditSubUserUsername)} type="text" placeholder={t("name-username")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-password")}</h2>
                                    </div>
                                    <div className="form-section__content">
                                        <div className="input-wrapper_fade">
                                            <input className="input" disabled={true} value={editSubUserPassword} onChange={(e) => (setEditSubUserPassword)} type="text" placeholder={t("name-password")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-traffic-available")}</h2>
                                        {/* {
                                            editSubUserData?.type === "residential-proxy-pool-1" &&
                                            <HintComponent
                                                content={t("name-traffic-available-pool-1-hint")}
                                            />
                                        } */}
                                    </div>
                                    <div className="form-section__content">
                                        <NumericInput
                                            min={0}
                                            max={
                                                currentTab === "name-pool-1-sub-users" ? (Number(basicSubUserDataPool1?.data?.traffic_available || 0) + Number(editSubUserData?.data?.traffic_available || 0)).toFixed(5) :
                                                    currentTab === "name-pool-2-sub-users" ? (Number(basicSubUserDataPool2?.data?.traffic_available || 0) + Number(editSubUserData?.data?.traffic_available || 0)).toFixed(5) : null
                                            }
                                            value={editSubUserTrafficAvailable}
                                            onChange={setEditSubUserTrafficAvailable}
                                            placeholder={t("name-traffic-available")}
                                        />
                                    </div>
                                </div>
                                <div className="form-section">
                                    <div className="form-section__header">
                                        <h2 className="form-section__h2">{t("name-traffic-used")}</h2>
                                    </div>
                                    <div className="form-section__content">
                                        <div className="input-wrapper_fade">
                                            <input className="input" disabled={true} value={editSubUserTrafficUsed} onChange={(e) => (setEditSubUserTrafficUsed)} type="number" placeholder={t("name-traffic-used")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup__footer">
                                <div className="buttons-wrapper">
                                    <button className="cancel-btn" onClick={(e) => (setIsOpenSubUserEditPopup(false))}>{t("button-cancel")}</button>
                                    <button className="primary-btn" disabled={!isActiveButtonSubmitEditSubUser} onClick={(e) => (handleUpdateSubUserSubmit())}>{t("button-submit-changes")}</button>
                                </div>
                            </div>
                        </Popup>
                        <Popup title={t("popup-title-sub-user-delete")} isSmall={true} trigger={isOpenSubUserDeletePopup} setTrigger={setIsOpenSubUserDeletePopup}>
                            <div className="popup__content">
                                <p className="text-to-delete">{t("name-confirm-delete-sub-user")} <b>"{deleteSubUserData?.data?.username}"</b></p>
                                <div className="form-section">
                                    <div className="form-section__content">
                                        <div className="input-wrapper">
                                            <input className="input" value={deleteSubUserUsername} onChange={(e) => (setDeleteSubUserUsername(e.target.value))} type="text" placeholder={t("name-username")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup__footer">
                                <div className="buttons-wrapper">
                                    <button className="cancel-btn" onClick={(e) => (setIsOpenSubUserDeletePopup(false))}>{t("button-cancel")}</button>
                                    <button
                                        className="delete-btn"
                                        onClick={(e) => (handleDeleteSubUserSubmit())}
                                        disabled={
                                            deleteSubUserUsername !== deleteSubUserData?.data?.username ||
                                            !isActiveButtonSubmitDeleteSubUser
                                        }
                                    >{t("button-confirm")}</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
            </div>
        </>
    );
};
