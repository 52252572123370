import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './Popup.css';

const Popup = ({ title, trigger, setTrigger, isSmall, children }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (trigger) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [trigger]);

    return (trigger) ? (
        <div className="overlay">
            <div className='container'>
                <div className={`popup ${isSmall ? 'small' : ''}`}>
                    <div className='popup__header'>
                        <h2 className='text-h2'>{t(title)}</h2>
                        <button className="close-btn" onClick={() => setTrigger(false)}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    ) : null;
};

export default Popup;
