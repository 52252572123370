const getTrafficStatsById = async (id, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/subUsers/${id}/trafficStats`;

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default getTrafficStatsById;
