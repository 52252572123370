import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import './ProfileSidebar.css';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../store/shopSlice';
import { fetchAvailableOrderTypes } from '../../store/availableOrderTypesSlice';

const ProfileSidebar = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const { availableOrderTypes } = useSelector((state) => state.availableOrderTypes);

    const [hasResidentialProxyOrderPool1, setHasResidentialProxyOrderPool1] = useState(false)
    const [hasResidentialProxyOrderPool2, setHasResidentialProxyOrderPool2] = useState(false)

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchAvailableOrderTypes(user.token));
    }, []);

    useEffect(() => {
        if (availableOrderTypes && availableOrderTypes.length > 0) {
            setHasResidentialProxyOrderPool1(availableOrderTypes.some(order => order.type === 'residential-proxy-pool-1'))
            setHasResidentialProxyOrderPool2(availableOrderTypes.some(order => order.type === 'residential-proxy-pool-2'))
        }
    }, [availableOrderTypes]);


    const location = useLocation();

    return (
        <aside className="profile-sidebar">
            <Link className="logo" to="/account">
                <img src='/logo.svg' alt='logo' />
            </Link>
            <nav className="profile-nav">
                <ul>
                    <li>
                        <Link
                            className={`profile-nav__link ${location.pathname === '/account/wallet' ? 'selected' : ''}`}
                            to="/account/wallet"
                        >
                            <img src='/wallet.svg' alt='wallet' />
                            <p>{t('name-wallet')}</p>
                        </Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link
                            className={`profile-nav__link ${location.pathname.includes('/account/shop') ? 'selected' : ''}`}
                            to="/account/shop"
                        >
                            <img src='/icons8-cart-64.png' alt='control_panel' />
                            <p>{t('name-shop')}</p>
                        </Link>
                    </li>
                </ul>
                <ul>
                    {hasResidentialProxyOrderPool1 && (
                        <li>
                            <Link
                                className={`profile-nav__link ${location.pathname === '/account/orders/residential-proxy/pool-1' ? 'selected' : ''}`}
                                to="/account/orders/residential-proxy/pool-1"
                            >
                                <img src='/control_panel.svg' alt='control_panel' />
                                <p>Pool 1</p>
                            </Link>
                        </li>
                    )}
                    {hasResidentialProxyOrderPool2 && (
                        <li>
                            <Link
                                className={`profile-nav__link ${location.pathname === '/account/orders/residential-proxy/pool-2' ? 'selected' : ''}`}
                                to="/account/orders/residential-proxy/pool-2"
                            >
                                <img src='/control_panel.svg' alt='control_panel' />
                                <p>Pool 2</p>
                            </Link>
                        </li>
                    )}
                </ul>
                <ul>
                    <li>
                        <Link
                            className={`profile-nav__link ${location.pathname.includes('/account/partnership') ? 'selected' : ''}`}
                            to="/account/partnership"
                        >
                            <img src='/office-team-teamwork-business-partnership-5-svgrepo-com.svg' alt='resell' />
                            <p>{t('name-api')}</p>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default ProfileSidebar;
