import { useState, useEffect } from "react";

import "./NumericInput.css";

const NumericInput = ({ 
    min = Number.MIN_SAFE_INTEGER, 
    max = Number.MAX_SAFE_INTEGER, 
    step = 1, 
    value = 0, 
    onChange, 
    placeholder = "Enter a number" 
}) => {
    const [inputValue, setInputValue] = useState(value.toString());
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    const clamp = (val) => Math.min(Math.max(val, min), max);

    const getDecimalPlaces = (num) => (num.toString().split(".")[1] || "").length;

    const handleChange = (e) => {
        let val = e.target.value;
        if (/^-?(\d+)?(\.\d*)?$/.test(val)) {
            setInputValue(val);
            setIsEditing(true);
        }
    };

    const commitChange = () => {
        if (!isEditing) return;
        if (inputValue === "" || inputValue === "-") {
            setInputValue("0");
            onChange?.(0);
            return;
        }
        let num = parseFloat(inputValue);
        if (!isNaN(num)) {
            const clampedValue = clamp(num);
            const decimalPlaces = getDecimalPlaces(step);
            setInputValue(clampedValue.toFixed(decimalPlaces));
            onChange?.(clampedValue);
        }
        setIsEditing(false);
    };

    const increment = () => {
        const newValue = clamp(parseFloat(inputValue || "0") + step);
        const decimalPlaces = getDecimalPlaces(step);
        setInputValue(newValue.toFixed(decimalPlaces));
        onChange?.(newValue);
        setIsEditing(false);
    };

    const decrement = () => {
        const newValue = clamp(parseFloat(inputValue || "0") - step);
        const decimalPlaces = getDecimalPlaces(step);
        setInputValue(newValue.toFixed(decimalPlaces));
        onChange?.(newValue);
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp") increment();
        if (e.key === "ArrowDown") decrement();
        if (e.key === "Enter") commitChange();
    };

    const handleMaxClick = () => {
        const newValue = max;
        setInputValue(newValue.toString());
        onChange?.(newValue);
    };

    return (
        <div className="input-wrapper_fade">
            <input
                className="input"
                type="text"
                value={inputValue}
                onChange={handleChange}
                onBlur={commitChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
            />
            <div className="input-wrapper__buttons">
                <button className="input-wrapper__btn" onClick={increment}>▲</button>
                <button className="input-wrapper__btn" onClick={decrement}>▼</button>
            </div>
            {max !== undefined && (
                <div className="input-wrapper__info" onClick={handleMaxClick}>
                    /<span>{`${max} GB`}</span>
                </div>
            )}
        </div>
    );
};

export default NumericInput;
