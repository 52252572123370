import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAvailableOrderTypes from '../api/orders/getAvailableOrderTypes.js';

export const fetchAvailableOrderTypes = createAsyncThunk('orders/fetchAvailableOrderTypes', async (token) => {
    const response = await getAvailableOrderTypes(token);
    return response;
});

const initialState = {
    availableOrderTypes: [],
    status: 'idle',
    error: null
};

const availableOrderTypesSlice = createSlice({
    name: 'availableOrderTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAvailableOrderTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAvailableOrderTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.availableOrderTypes = action.payload;
            })
            .addCase(fetchAvailableOrderTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default availableOrderTypesSlice.reducer;
