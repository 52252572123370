import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { toast } from 'react-toastify';

import style from "./InputWithCopyComponent.css";

const InputWithCopyComponent = ({ value, type = "text", disabled, onChange }) => {
    const { t } = useTranslation();

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
                toast.success(t('notification-text-copied'));
            })
            .catch((err) => {
                toast.error(t('error-copying-text'));
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <div className='input-wrapper'>
            <input
                className="input"
                type={type}
                value={value}
                disabled={disabled}
                onChange={(e) => onChange && onChange(e.target.value)}
            />
            <button
                className='copy-btn'
                onClick={handleCopy}
            >
                {isCopied ? 'Copied!' : 'Copy'}
            </button>
        </div>
    );
};

export default InputWithCopyComponent;
